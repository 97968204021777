<template>
  <div>
    <v-row justify="space-around">
      <v-card :elevation="0" outlined :loading="loading">
        <!--<v-img
          height="200"
          src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg"
          cover
          class="text-white"
        ></v-img>-->

        <counter />
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-card
                :elevation="0"
                color="grey-lighten-4"
                flat
                min-height="200px"
                outlined
                v-for="c in campaigns"
                :key="c.id"
              >
                <v-toolbar color="primary" :dark="true">
                  <v-toolbar-title>
                    {{ c.name }}
                    <br />
                    <span>
                      {{ c.campaign_details.vp.administrative_region }}
                    </span>
                    <!--<v-btn
                      class="ma-2"
                      color="green"
                      @click="getCampaignDetails(c)"
                    >
                      <v-icon
                        icon="mdi mdi-view-dashboard-variant-outline"
                      ></v-icon>
                      View Details
                    </v-btn>-->
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-title class="hidden-sm-and-down">
                    {{ c.start_date }} - {{ c.end_date }}
                  </v-toolbar-title>
                </v-toolbar>
                <!--<v-card-text class="pa-3">
                  <v-progress-linear
                    :value="voter_progress"
                    color="green"
                    height="25"
                  >
                    <template v-slot:default="{ value }">
                      <strong>VOTER PROGRESS: {{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                  <div class="col-12 text-center">
                    <strong>
                      Total {{ c.voters }} Assigned /
                      {{ getDays(new Date(c.end_date), new Date()) }} Days
                      Remaining
                    </strong>
                  </div>
                </v-card-text>-->
                <v-card-text>
                  <div>
                    <campaign-details
                      :campaign="c"
                      :data="c.campaign_details"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <!--<div class="row">
            <div class="col-12">
              <v-card :elevation="0" outlined>
                <v-card-title class="primary">NOTICES</v-card-title>
                <v-card-text>
                  <v-list lines="three">
                    <v-list-item v-for="item in news" :key="item.id">
                      <div class="row mb-4">
                        <div class="col-3">
                          <image-field
                            size="mini"
                            :disabled="true"
                            :show-zoom="false"
                            :image-click="true"
                            :grid="true"
                            :width="0"
                            :height="50"
                            :value="item.photo"
                          />
                        </div>
                        <div class="col-9">
                          <div class="row">
                            <div class="col-12 pb-0 title">
                              {{ item.subject }}
                            </div>
                            <div
                              class="col-12 pt-0 pb-0"
                              style="max-height: 35px; overflow: hidden"
                            >
                              {{ item.details }}
                            </div>
                            <div
                              class="col-12 pt-0"
                              style="max-height: 35px; overflow: hidden"
                            >
                              {{ item.campaign }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="font-weight-bold ms-1 mb-2">Recently</div>
              <el-timeline>
                <el-timeline-item timestamp="Janury 22, 2024" placement="top">
                  <el-card>
                    <h4>Opened Jagaha in Guraidhoo</h4>
                    <p>A new jagaha was opened in Guraidhoo ...</p>
                    <el-button size="mini">view more</el-button>
                  </el-card>
                </el-timeline-item>
                <el-timeline-item timestamp="Janury 22, 2024" placement="top">
                  <el-card>
                    <h4>Opened Jagaha in Guraidhoo</h4>
                    <p>A new jagaha was opened in Guraidhoo ...</p>
                    <el-button size="mini">view more</el-button>
                  </el-card>
                </el-timeline-item>
                <el-timeline-item timestamp="Janury 22, 2024" placement="top">
                  <el-card>
                    <h4>Opened Jagaha in Guraidhoo</h4>
                    <p>A new jagaha was opened in Guraidhoo ...</p>
                    <el-button size="mini">view more</el-button>
                  </el-card>
                </el-timeline-item>
              </el-timeline>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"></div>
          </div>-->
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
//import ImageField from "@/components/custom/ImageField.vue";
import campaignDetails from "./campaignDetails.vue";
import counter from "./counter.vue";
import Vue from "vue";
import { Button, Card, Timeline, TimelineItem } from "element-ui";
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Card);
Vue.use(Button);
export default {
  created() {
    this.getDashboard();
  },
  components: {
    //ImageField,
    campaignDetails,
    counter,
  },
  data() {
    return {
      valid: true,
      loading: false,
      voter_progress: 25,
      news: [],
      campaigns: [],
      campaign_details: null,
      messages: [
        {
          from: "You",
          message: `Sure, I'll see you later.`,
          time: "11:42am",
          color: "deep-purple-lighten-1",
        },
        {
          from: "John Doe",
          message: "Yeah, sure. Does 1:00pm work?",
          time: "10:37am",
          color: "green",
        },
        {
          from: "You",
          message: "Did you still want to grab lunch today?",
          time: "9:47am",
          color: "deep-purple-lighten-1",
        },
      ],
    };
  },
  methods: {
    getDashboard() {
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
        id_card_no: this.id_card_no,
        mobile_no: this.mobile_no,
      };
      this.api("public/vp-dashboard", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.news = response.news;
            this.campaigns = response.campaigns;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCampaignDetails(campaign) {
      this.loading = true;
      let f = {
        campaigns_id: campaign.id,
      };
      this.api("public/campaign-dashboard", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            console.log(response.data);
            this.campaign_details = response;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "Home",
};
</script>
