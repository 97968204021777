<template>
  <div class="row">
    <div class="col-12">
      <!--<v-card :elevation="0" outlined class="pa-0">-->
      <!--<div class="col-12 pa-0" v-if="!empty(data) && !empty(data.vp)">
          <v-alert color="primary" theme="light" prominent class="pa-0">
            <v-toolbar color="primary" :dark="true">
              <v-toolbar-title>
                VP Dashboard
                <br class="d-flex d-sm-none" />
                <span class="d-flex d-sm-none">
                  {{ data.vp.administrative_region }}
                </span>
              </v-toolbar-title>
              <v-spacer />
              <br />
              <v-toolbar-title class="d-none d-sm-flex">
                {{ data.vp.administrative_region }}
              </v-toolbar-title>
            </v-toolbar>
          </v-alert>
        </div>-->
      <div class="row">
        <div
          v-if="!empty(data)"
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center"
        >
          <h3>Votes by Candidate</h3>
          <GChart
            type="ColumnChart"
            :options="pollPieChartOptions"
            :data="candidate_votes"
          />
        </div>
        <div
          v-if="!empty(data)"
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center"
        >
          <h3>Votes by Status</h3>
          <GChart
            type="ColumnChart"
            :options="pollPieChartOptions"
            :data="voterStatusPieDta"
          />
        </div>
      </div>
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-if="!empty(data)"
      >
        <el-table :data="data.summary" style="width: 100%" :show-header="false">
          <el-table-column fixed prop="title" label="Title"></el-table-column>
          <el-table-column
            fixed="right"
            label="Count"
            width="120"
            align="right"
            prop="count"
          >
            <template slot-scope="scope">
              {{ formatNumber(scope.row.count, 0) }}
              <span
                class="mdi mdi-eye-arrow-right-outline green--text pointer"
                v-if="scope.row.count > 0"
                @click="viewMore(scope.row) && !empty(scope.row.action)"
              ></span>
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="data.islands" style="width: 100%">
          <el-table-column fixed prop="name" width="200" label="Island" />
          <el-table-column label="Voters" prop="voters">
            <template slot-scope="scope">
              <div>
                <span v-for="v in scope.row.voters" :key="v.status">
                  <v-chip class="ma-2" :color="v.color" text-color="white">
                    {{ v.status }}: {{ v.voters }}
                  </v-chip>
                </span>
                <br />
                {{ scope.row.volunteers }} Volunteers
              </div>
              <span
                class="mdi mdi-eye-arrow-right-outline green--text pointer"
                v-if="scope.row.voters > 0 && !empty(scope.row.action)"
                @click="viewMore(scope.row)"
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="Voters" prop="voters">
            <template slot-scope="scope">
              <GChart
                type="PieChart"
                :options="getChartOptions(scope.row.voters)"
                :data="getChartData(scope.row.voters)"
              />
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="data.volunteers" style="width: 100%">
          <el-table-column fixed prop="full_name" label="Volunteer">
            <template slot-scope="scope">
              {{ scope.row.full_name }}
              <br />
              <v-progress-linear
                :value="
                  scope.row.reached_voters_count /
                  scope.row.volunteer_voters_count
                "
                color="orange"
                height="25"
              >
                <template v-slot:default="{ value }">
                  <strong>REACHED: {{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
              <v-progress-linear
                :value="
                  scope.row.guaranteed_voters_count /
                  scope.row.volunteer_voters_count
                "
                color="green"
                height="25"
              >
                <template v-slot:default="{ value }">
                  <strong>CONFIRMED: {{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Voters"
            width="120"
            align="right"
            prop="voters"
          >
            <template slot-scope="scope">
              <div>
                {{ formatNumber(scope.row.volunteer_voters_count, 0) }} Voters
                <br />
                {{ scope.row.reached_voters_count }} Reached
                <br />
                {{ scope.row.guaranteed_voters_count }} Guaranteed
              </div>
              <span
                class="mdi mdi-eye-arrow-right-outline green--text pointer"
                @click="viewMore(scope.row)"
              ></span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <view-voter
        :show="showVoterList"
        :campaign="campaign"
        :list="selectedList"
        @close="closeVoterList"
      />
      <!--</v-card>-->
    </div>
  </div>
</template>
<script>
//import ImageField from "@/components/custom/ImageField.vue";
import { GChart } from "vue-google-charts";
import Vue from "vue";
import { Table, TableColumn } from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
import viewVoter from "./viewVoter.vue";
export default {
  watch: {
    data: function () {
      this.prepareData();
    },
  },
  props: {
    data: null,
    campaign: null,
  },
  created() {
    this.prepareData();
  },
  components: {
    GChart,
    viewVoter,
    //ImageField,
  },
  data() {
    return {
      valid: true,
      candidate_votes: [],
      voterStatusPieDta: [],
      pollPieChartOptions: {
        legend: { position: "none" },
        vAxis: { minValue: 0 },
        //title: "Voter Response",
        colors: [
          "#ff0000",
          "#0a225f",
          "#7892d3",
          "#ff9898",
          "#67c23a",
          "#78735a",
          "#645252",
          "#9ebcff",
          "#ecf5ff",
          "#e6a23c",
        ],
        is3D: true,
      },
      selectedList: null,
      showVoterList: false,
    };
  },
  methods: {
    prepareData() {
      if (!this.empty(this.data)) {
        console.log(this.data);
        this.$nextTick(() => {
          this.candidate_votes = this.getCandidateChartData(
            this.data.candidate_votes
          );
          let f = [
            "Status",
            "Voters",
            { role: "style" },
            { role: "annotation" },
          ];
          this.voterStatusPieDta.push(f);
          let vs = this.data.voter_status;
          vs.forEach((el) => {
            let a = [
              el.status,
              parseInt(el.voters),
              el.color,
              parseInt(el.voters),
            ];
            this.voterStatusPieDta.push(a);
          });
        });
      }
    },
    getCandidateChartData(data) {
      let f = [
        "full_name",
        "Voters",
        { role: "style" },
        { role: "annotation" },
      ];
      let cdata = [];
      cdata.push(f);
      data.forEach((el) => {
        let a = [
          el.full_name,
          parseInt(el.voters),
          el.color,
          parseInt(el.voters),
        ];
        cdata.push(a);
      });
      return cdata;
    },
    getChartData(data) {
      let f = ["Status", "Voters", { role: "style" }, { role: "annotation" }];
      let cdata = [];
      cdata.push(f);
      data.forEach((el) => {
        let a = [el.status, parseInt(el.voters), el.color, parseInt(el.voters)];
        cdata.push(a);
      });
      return cdata;
    },
    getChartOptions(data) {
      var col = [];
      data.forEach((el) => {
        col.push(el.color);
      });
      return {
        legend: { position: "none" },
        vAxis: { minValue: 0 },
        //title: "Voter Response",
        colors: col,
        is3D: true,
      };
    },
    viewMore(row) {
      this.selectedList = row;
      this.showVoterList = true;
    },
    closeVoterList() {
      this.selectedList = null;
      this.showVoterList = false;
    },
  },
  name: "HomeCampaignDetails",
};
</script>
